import React from "react";
import './Spinner.css';
import classNames from "classnames";

export interface SpinnerProps {
    className?: string;
}

export const Spinner = ({className}: SpinnerProps) => (
    <div className={classNames("spinner", className)}/>
);
