import React from "react";
import {Button} from "../Button/Button";
import {Popover} from "@mui/material";
import {RgbStringColorPicker} from 'react-colorful';


export interface ColorPickerProps {
    value: string
    onChange: (value: string) => void
    disabled?: boolean
}

export const ColorPicker = React.forwardRef<HTMLElement, ColorPickerProps>((props, _ref) => {
    const {value, onChange, disabled} = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const ref = React.useRef<HTMLButtonElement>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={disabled ? {pointerEvents: 'none', opacity: 0.5} : undefined}>
            <Button ref={ref} onClick={() => setAnchorEl(ref.current)} style={{width: 170}}>
                {value}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{padding: 16}}>
                    <RgbStringColorPicker color={value} onChange={onChange}/>
                </div>
            </Popover>
        </div>
);
})