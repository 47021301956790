import {useRouteError, Link} from "react-router-dom";
import React from "react";
import './NotFoundErrorPage.css';

export const NotFoundErrorPage = () => {
    const error = useRouteError() as {statusText?: string, message?: string};
    console.error(error);

    return (
        <div className='not-found_container'>
            <article className='not-found_description'>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred:</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
                <p>
                    Try <Link to='/'>Main page</Link>
                </p>
            </article>
        </div>
    );
};
