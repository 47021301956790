import React from "react";

export interface FormLabelProps {
    text: string;
}

export const FormLabel = ({text}: FormLabelProps) => (
    <p style={{marginBottom: 8}}>
        {text}
    </p>
);
