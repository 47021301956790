import {Form, FormGroup, FormLabel} from "../shared/ui/Form";
import React from "react";
import {FormProps} from "../shared/ui/Form/Form";
import {DeepPartial, FieldValues} from "react-hook-form";
import {FormNumberInput} from "../shared/ui/Form/FormNumberInput";
import {uncorrApi} from "../shared/service/uncorrApi";
import {useAppSelector} from "../shared/hooks/store";
import {getDatasetParams} from "../shared/store/datasetParamsSlice";

export interface CallbackProps {
    xMin?: number
    xMax?: number
    yMin?: number
    yMax?: number
}

export function InterpolationForm<V extends FieldValues>(props: FormProps<V>) {

    const datasetParams = useAppSelector(getDatasetParams);
    const {data} = uncorrApi.endpoints.getDatasetStat.useQueryState(datasetParams!);

    const defaults = {
        xMin: data!.xMin,
        xMax: data!.xMax,
        yMin: data!.yMin,
        yMax: data!.yMax,
    };

    return (
        <Form {...props}>
            <FormGroup>
                <FormLabel text='xMin'/>
                <FormNumberInput name='xMin' placeholder={String(defaults.xMin)}/>
            </FormGroup>
            <FormGroup>
                <FormLabel text='xMax'/>
                <FormNumberInput name='xMax' placeholder={String(defaults.xMax)}/>
            </FormGroup>
            <FormGroup>
                <FormLabel text='yMin'/>
                <FormNumberInput name='yMin' placeholder={String(defaults.yMin)}/>
            </FormGroup>
            <FormGroup>
                <FormLabel text='yMax'/>
                <FormNumberInput name='yMax' placeholder={String(defaults.yMax)}/>
            </FormGroup>
            {props.children}
        </Form>
    );
}
