import createBaseSlice from "./createBaseSlice";
import {RootState} from "./store";
import {IdwParams} from "../types/params/idw";
import {RbfParams} from "../types/params/rbf";
import {LinearParams} from "../types/params/linear";
import {NearestNeighbourParams} from "../types/params/nearestNeighbour";
import {OrdinaryKrigingParams} from "../types/params/kriging/ordinary";
import {UniversalKrigingParams} from "../types/params/kriging/universal";
import {SimpleKrigingParams} from "../types/params/kriging/simple";

export const idwParamsSlice = createBaseSlice('idwParams', null as IdwParams | null);

export const rbfParamsSlice = createBaseSlice('rbfParams', null as RbfParams | null);

export const linearParamsSlice = createBaseSlice('linearParams', null as LinearParams | null);

export const nearestNeighbourParamsSlice = createBaseSlice('nearestNeighbourParams', null as NearestNeighbourParams | null);

export const simpleKrigingParamsSlice = createBaseSlice('simpleKrigingParams', null as SimpleKrigingParams | null);

export const ordinaryKrigingParamsSlice = createBaseSlice('ordinaryKrigingParams', null as OrdinaryKrigingParams | null);

export const universalKrigingParamsSlice = createBaseSlice('universalKrigingParams', null as UniversalKrigingParams | null);


export const { setIdwParams } = idwParamsSlice.actions;
export const getIdwParams = (state: RootState) => state.idwParams;

export const { setRbfParams } = rbfParamsSlice.actions;
export const getRbfParams = (state: RootState) => state.rbfParams;

export const { setLinearParams } = linearParamsSlice.actions;
export const getLinearParams = (state: RootState) => state.linearParams;

export const { setNearestNeighbourParams } = nearestNeighbourParamsSlice.actions;
export const getNearestNeighbourParams = (state: RootState) => state.nearestNeighbourParams;

export const { setSimpleKrigingParams } = simpleKrigingParamsSlice.actions;
export const getSimpleKrigingParams = (state: RootState) => state.simpleKrigingParams;

export const { setOrdinaryKrigingParams } = ordinaryKrigingParamsSlice.actions;
export const getOrdinaryKrigingParams = (state: RootState) => state.ordinaryKrigingParams;

export const { setUniversalKrigingParams } = universalKrigingParamsSlice.actions;
export const getUniversalKrigingParams = (state: RootState) => state.universalKrigingParams;
