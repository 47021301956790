import React from "react";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {InterpolatorProps} from "../../shared/types/interpolator";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/store";
import {getStage} from "../../shared/store/interpolationStageSlice";
import {Block} from "../../shared/ui/Block/Block";
import {Interpolation} from "./Interpolation";
import {getRbfParams, setRbfParams} from "../../shared/store/methodsParamsSlices";
import {CallbackProps, InterpolationForm} from "../InterpolationForm";
import {FormGroup, FormLabel, FormSelect} from "../../shared/ui/Form";
import {FormNumberInput} from "../../shared/ui/Form/FormNumberInput";
import {Option} from "../../shared/ui/Form/types";
import {Kernel} from "../../shared/types/params/rbf";

export interface RbfFormCallbackProps extends CallbackProps {
    neighbors?: number
    smoothing?: number
    degree?: number
    kernel?: Option;
}

const kernelOptions = [
    {value: 'linear', label: 'Linear'},
    {value: 'thin_plate_spline', label: 'Thin-plate Spline'},
    {value: 'cubic', label: 'Cubic'},
];

export const Rbf = ({datasetParams}: InterpolatorProps) => {

    const dispatch = useAppDispatch();
    const rbfParams = useAppSelector(getRbfParams);
    const stage = useAppSelector(getStage);

    const onSubmit = React.useCallback((props: RbfFormCallbackProps) => {
        dispatch(setRbfParams({
            ...props,
            kernel: props.kernel?.value as Kernel,
        }));
    }, []);

    return (
        <>
            <Block title='Customize interpolation'>
                <InterpolationForm
                    onSubmit={onSubmit}
                    defaultValues={{
                        ...(rbfParams ?? undefined),
                        kernel: kernelOptions.find(option => option.value === rbfParams?.kernel)
                            || {value: 'cubic', label: 'Cubic'},
                    }}
                >
                    <FormGroup>
                        <FormLabel text='Kernel'/>
                        <FormSelect name='kernel' options={kernelOptions}/>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel text='Neighbors'/>
                        <FormNumberInput name='neighbors' placeholder='100'/>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel text='Smoothing'/>
                        <FormNumberInput name='smoothing' placeholder='0'/>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel text='Degree'/>
                        <FormNumberInput name='degree' placeholder='1'/>
                    </FormGroup>
                </InterpolationForm>
            </Block>
            {stage >= 3 && rbfParams && (
                <Interpolation useGetQuery={() => uncorrApi.useGetRbfQuery({...datasetParams, ...rbfParams})}/>
            )}
        </>
    );
};
