import {Controller, FieldPath, FieldValues, useFormContext} from "react-hook-form";
import {ColorRangeList} from "../ColorRangeList/ColorRangeList";

export interface FormColorRangeListProps<V extends FieldValues> {
    name: FieldPath<V>
    required?: boolean
    disabled?: boolean
}

export function FormColorRangeList<V extends FieldValues>(props: FormColorRangeListProps<V>) {
    const {name, required, disabled} = props;
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            rules={{required}}
            control={control}
            render={({field}) => (
                <ColorRangeList value={field.value} onChange={field.onChange} disabled={disabled}/>
            )}
        />
    );
}
