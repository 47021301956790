import {DatasetParams} from "../../shared/types/dataset";
import {Points} from "../../shared/types/field";
import {Form, FormGroup, FormLabel, FormSelect} from "../../shared/ui/Form";
import {Option} from "../../shared/ui/Form/types";
import {useFormContext} from "react-hook-form";
import React from "react";
import {ScatterMapPlot} from "../../widgets/dataset/ScatterMapPlot";
import {ScatterPlot} from "../../widgets/dataset/ScatterPlot";

export interface DatasetPlotProps {
    datasetParams: DatasetParams;
    points: Points;
    width: number;
}

interface FormCallback {
    coords: Option;
    utmZone: string;
}

interface FormState {
    coords: string;
    utmZoneNumber: number;
    utmZoneLetter: string;
}

const coordsOptions = [
    {label: 'XYZ', value: 'xyz'},
    {label: 'Lat Lon', value: 'll'},
    {label: 'UTM', value: 'utm'},
];

const UtmZoneInput = () => {
    const methods = useFormContext();
    const coords: Option = methods.watch('coords');
    const disabled = coords.value !== 'utm';

    return (
        <FormGroup>
            <FormLabel text='Utm zone'/>
            <input
                {...methods.register('utmZone', {
                    pattern: /\d{1,2}[C-X]/,
                })}
                disabled={disabled}
                style={{
                    width: 210,
                    padding: 8,
                    border: '1px solid hsl(0, 0%, 80%)',
                    borderRadius: 4,
                    fontSize: 16,
                }}
            />
        </FormGroup>
    );
}

export const DatasetPlot = (props: DatasetPlotProps) => {
    const {datasetParams, points, width} = props;

    const [data, setData] = React.useState<FormState>({
        coords: 'xyz',
        utmZoneNumber: 17,
        utmZoneLetter: 'T',
    });

    const formValues = {
        coords: coordsOptions.find(option => option.value === data.coords)!,
        utmZone: data.utmZoneNumber + data.utmZoneLetter,
    };

    const onSubmit = (d: FormCallback) => setData({
        coords: d.coords.value,
        utmZoneNumber: parseInt(d.utmZone),
        utmZoneLetter: d.utmZone[d.utmZone.length - 1],
    })


    return (
        <>
            <Form
                onSubmit={onSubmit}
                values={formValues}
            >
                <FormGroup>
                    <FormLabel text='Coordinate system'/>
                    <FormSelect name='coords' options={coordsOptions}/>
                </FormGroup>
                <UtmZoneInput/>
            </Form>
            {data.coords === 'xyz'
                ? (
                    <ScatterPlot
                        columns={datasetParams.columns}
                        points={points}
                        width={width * 0.8}
                        height={width * 0.6}
                    />
                )
                : (
                    <ScatterMapPlot
                        columns={datasetParams.columns}
                        points={points}
                        width={width * 0.8}
                        height={width * 0.6}
                        convertUtm={data.coords === 'utm'}
                        utmZoneNumber={data.utmZoneNumber}
                        utmZoneLetter={data.utmZoneLetter}
                    />
                )
            }
        </>
    );
}