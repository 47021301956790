export interface Function {
    (...args: any[]): void;
}

export function debounce<F extends Function>(f: F, delayMs: number) {
    let isCooldown = false;
    return (...args: Parameters<F>) => {
        if (isCooldown) {
            return;
        }
        f.apply(args);
        isCooldown = true;
        setTimeout(() => {
            isCooldown = false;
        }, delayMs);
    };
}