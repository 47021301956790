import {NumberInput} from "../NumberInput/NumberInput";
import {Button} from "../Button/Button";
import {ColorPicker} from "../ColorPicker/ColorPicker";

export type ColorPoints = Array<[number, string]>;

export interface ColorRangeListProps {
    value: ColorPoints,
    onChange: (colorPoints: ColorPoints) => void,
    disabled?: boolean
}

export function ColorRangeList({value, onChange, disabled}: ColorRangeListProps) {
    const remove = (i: number) => () => {
        const copy = value.slice();
        copy.splice(i, 1);
        onChange(copy);
    };
    const add = () => {
        value.push([0, 'rgb(0, 0, 0)']);
        onChange(value);
    };
    const changePoint = (i: number) => (point: number | null) => {
        if (point === null) {
            return;
        }
        value[i][0] = point;
        onChange(value);
    };
    const changeColor = (i: number) => (color: string) => {
        value[i][1] = color;
        onChange(value);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 8,
            ...(disabled ? {pointerEvents: 'none', opacity: 0.5} : undefined)
        }}>
            {value.map(([point, color], i) => (
                <div key={i} style={{display: 'flex', columnGap: 8}}>
                    <NumberInput value={point} onChange={changePoint(i)}/>
                    <ColorPicker value={color} onChange={changeColor(i)}/>
                    <Button onClick={remove(i)}>-</Button>
                </div>
            ))}
            <Button onClick={add} style={{width: 200}}>+</Button>
        </div>
    );
}