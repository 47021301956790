import React from 'react';
import './Error.css';


export interface ErrorProps {
    message?: string;
}

export const Error = ({message}: ErrorProps) => (
    <p className='error'>
        {message || 'Unknown error'}
    </p>
);
