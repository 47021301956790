import {RootState} from "./store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {clearDatasetParams, setDatasetParams} from "./datasetParamsSlice";
import {setMethod} from "./methodSlice";
import {setEmpiricParams, setModelParams} from "./variogramSlices";
import {
    setIdwParams,
    setNearestNeighbourParams,
    setLinearParams,
    setOrdinaryKrigingParams, setSimpleKrigingParams,
    setRbfParams, setUniversalKrigingParams
} from "./methodsParamsSlices";


export const interpolationStageSlice = createSlice({
    name: 'stage',
    initialState: 0,
    reducers: {
        setStage: (state, action: PayloadAction<number>) => action.payload,
    },
    extraReducers: builder => {
        builder.addCase(clearDatasetParams, () => 0);
        builder.addCase(setDatasetParams, () => 1);
        builder.addCase(setMethod, () => 2);

        builder.addCase(setIdwParams, () => 3);
        builder.addCase(setNearestNeighbourParams, () => 3);
        builder.addCase(setRbfParams, () => 3);
        builder.addCase(setLinearParams, () => 3);

        builder.addCase(setEmpiricParams, () => 3);
        builder.addCase(setModelParams, () => 4);

        builder.addCase(setSimpleKrigingParams, () => 5);
        builder.addCase(setOrdinaryKrigingParams, () => 5);
        builder.addCase(setUniversalKrigingParams, () => 5);
    },
});

export const getStage = (state: RootState) => state.stage;
