import React from "react";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {InterpolatorProps} from "../../shared/types/interpolator";
import {getLinearParams, setLinearParams} from "../../shared/store/methodsParamsSlices";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/store";
import {getStage} from "../../shared/store/interpolationStageSlice";
import {Block} from "../../shared/ui/Block/Block";
import {Interpolation} from "./Interpolation";
import {CallbackProps, InterpolationForm} from "../InterpolationForm";

export const Linear = ({datasetParams}: InterpolatorProps) => {

    const dispatch = useAppDispatch();
    const linearParams = useAppSelector(getLinearParams);
    const stage = useAppSelector(getStage);

    const onSubmit = React.useCallback((props: CallbackProps) => {
        dispatch(setLinearParams(props));
    }, []);

    return (
        <>
            <Block title='Customize interpolation'>
                <InterpolationForm onSubmit={onSubmit} defaultValues={linearParams ?? undefined}/>
            </Block>
            {stage >= 3 && linearParams && (
                <Interpolation useGetQuery={() => uncorrApi.useGetLinearQuery({...datasetParams, ...linearParams})}/>
            )}
        </>
    );
};
