import {Controller, FieldValues, FieldPath, useFormContext} from "react-hook-form";
import {NumberInput} from "../NumberInput/NumberInput";


export interface FormNumberInputProps<V extends FieldValues> {
    name: FieldPath<V>
    required?: boolean
    placeholder?: string
    disabled?: boolean
}

export function FormNumberInput<V extends FieldValues>(props: FormNumberInputProps<V>) {
    const {name, required, placeholder, disabled} = props;
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            rules={{required}}
            control={control}
            render={({field}) => (
                <NumberInput
                    {...field}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            )}
        />
    );
}