import React from "react";
import Plot, {PlotParams} from "react-plotly.js";
import * as Plotly from "plotly.js";

export interface BasePlotProps extends PlotParams {
    defaultLayout?: Partial<Plotly.Layout>;
    defaultConfig?: Partial<Plotly.Config>;
}

export interface BasePlotState {
    layout: Partial<Plotly.Layout>;
    config?: Partial<Plotly.Config>;
}

export class BasePlot extends React.Component<BasePlotProps, BasePlotState> {

    constructor(props: BasePlotProps) {
        super(props);
        this.state = {
            layout: props.defaultLayout || {},
            config: {
                editable: true,
                toImageButtonOptions: {
                    format: 'svg',
                    filename: 'chart',
                    width: 620,
                    height: 480,
                },
                displaylogo: false,
                ...props.defaultConfig,
            },
        };
    }

    static getDerivedStateFromProps(props: BasePlotProps, state: BasePlotState) {
        return {
            layout: {
                ...state.layout,
                ...props.layout,
            },
            config: {
                ...state.config,
                ...props.config,
            },
        };
    }

    render() {
        return (
            <Plot
                {...this.props}
                layout={this.state.layout}
                config={this.state.config}
            />
        );
    }
}
