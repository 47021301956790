import React from "react";
import {InterpolationPage} from "../pages/InterpolationPage/InterpolationPage";
import {Header} from "../widgets/header/Header";
import {Footer} from "../widgets/footer/Footer";
import "./App.css";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import store from "../shared/store/store";
import {Provider} from "react-redux";
import {DocsPage} from "../pages/DocsPage/DocsPage";
import {NotFoundErrorPage} from "../pages/NotFoundErrorPage/NotFoundErrorPage";
import {CommandPage} from "../pages/CommandPage/CommandPage";
import {EmbeddedPage} from "../pages/EmbeddedPage/EmbeddedPage";

const Root = () => (
    <div className="app">
        <Header className='app_header'/>
        <main className='app_main'>
            <Outlet/>
        </main>
        <Footer/>
    </div>
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        children: [
            {
                index: true,
                element: <InterpolationPage/>,
            },
            {
                path: "docs",
                element: <DocsPage/>,
            },
            {
                path: "cmd",
                element: <CommandPage/>
            }
        ],
        errorElement: <NotFoundErrorPage/>,
    },
    {
        path: '/embedded',
        element: <EmbeddedPage/>,
        errorElement: <NotFoundErrorPage/>,
    }
]);

export const App = () => (
    <Provider store={store}>
        <RouterProvider router={router}/>
    </Provider>
);
