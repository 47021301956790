import React from "react";
import {Points} from "../shared/types/field";
import {Data} from 'plotly.js';
import {getMax, getMin} from "../shared/utils/normalize";
import {BasePlot} from "../shared/types/BasePlot";

export interface InterpolationPlotProps {
    columns: string[];
    points: Points;
    width: number;
    height: number;

    isDefaultContoursEnabled: boolean
    ncontours?: number
    showlabels?: boolean
    color?: string

    additionalContours: Array<[number, string]>

    colorscale: string | Array<[number, string]>

    revision?: number
}

export const InterpolationPlot = (props: InterpolationPlotProps) => {
    const {
        columns,
        points,
        width,
        height,

        isDefaultContoursEnabled,
        ncontours,
        showlabels,
        color,

        additionalContours,

        colorscale,
        revision,
    } = props;

    const additionalContoursData: Data[] = additionalContours.map(([value, color]) => ({
        x: points[0],
        y: points[1],
        z: points[2],
        type: 'contour',
        connectgaps: true,
        line: {
            smoothing: 0.7,
            color,
            width: 1,
        },
        autocontour: false,
        contours: {
            type: 'constraint',
            value,
            coloring: 'none',
            showlabels: true,
        },
    }));

    return (
        <BasePlot
            data={[
                {
                    x: points[0],
                    y: points[1],
                    z: points[2],
                    type: isDefaultContoursEnabled ? 'contour' : 'heatmap',
                    connectgaps: true,
                    line: {
                        smoothing: 0.7,
                        color,
                    },
                    autocontour: false,
                    contours: {
                        coloring: 'heatmap',
                        start: getMin(points[2]),
                        end: getMax(points[2]),
                        size: ncontours && ((getMax(points[2]) - getMin(points[2])) / ncontours),
                        showlabels,
                    },
                    colorscale,
                    colorbar: {
                        title: columns[2],
                        exponentformat: 'none',

                    },
                },
                ...additionalContoursData,
            ]}
            revision={revision}
            defaultLayout={{
                title: 'Interpolation plot',
                xaxis: {title: columns[0], exponentformat: 'none'},
                yaxis: {title: columns[1], exponentformat: 'none'},
            }}
            layout={{
                width,
                height,
                datarevision: revision,
            }}
        />
    )
};
