import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export interface InterpolationPlotSettings {
    isDefaultContoursEnabled: boolean
    ncontours: number
    showlabels: boolean
    color: string

    additionalContours: Array<[number, string]>

    isDefaultColorscale: boolean
    colorscaleType: string
    colorscaleRanges: Array<[number, string]>

    revision: number
}

function incMod(n: number) {
    return (n + 1) % 1000;
}

export const interpolationPlotSettingsSlice = createSlice({
    name: 'interpolationPlotSettings',
    initialState: {
        isDefaultContoursEnabled: true,
        ncontours: 10,
        showlabels: true,
        color: 'rgb(255, 255, 255)',
        additionalContours: [],
        isDefaultColorscale: true,
        colorscaleType: 'RdBu',
        colorscaleRanges: [],
        revision: 0,
    } as InterpolationPlotSettings,
    reducers: {
        setInterpolationPlotSettings: (state, action: PayloadAction<Omit<InterpolationPlotSettings, 'revision'>>) => ({
            ...action.payload,
            revision: incMod(state.revision),
        }),
        incRevision: state => ({...state, revision: incMod(state.revision)}),
    },
});

export const getInterpolationPlotSettings = (state: RootState) => state.interpolationPlotSettings;
export const {setInterpolationPlotSettings, incRevision} = interpolationPlotSettingsSlice.actions;
