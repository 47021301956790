import {Points} from "../../shared/types/field";
import {Button} from "../../shared/ui/Button/Button";
import {toCsv} from "../../shared/utils/toCsv";
import React from "react";

export interface DownloadPointsButtonProps {
    columns: string[];
    points: Points;
}

export const DownloadPointsButton = ({columns, points}: DownloadPointsButtonProps) => (
    <Button onClick={() => {
        const link = document.createElement('a');
        link.download = 'interpolation_grid.csv';
        const blob = new Blob([toCsv(columns, points)], {type: 'text/csv'});
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link); // Required for FF
        link.click();
    }}>
        Download interpolation grid
    </Button>
);
