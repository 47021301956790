import React from "react";
import {InterpolationPage} from "../InterpolationPage/InterpolationPage";
import {processQueryParamsState} from "../../shared/utils/processQueryParamsState";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {useAppDispatch} from "../../shared/hooks/store";
import {useQueryParams} from "../../shared/hooks/useQueryParams";
import store from "../../shared/store/store";


export const CommandPage = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [completed, setCompleted] = React.useState(false);
    const dispatch = useAppDispatch();
    const params = useQueryParams();

    const scrollToBottom = () => {
        ref.current?.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
    }

    React.useEffect(() => {
        const interval = setInterval(() => scrollToBottom(), 500);
        window.addEventListener('wheel', () => clearInterval(interval), {once: true});
        return () => clearInterval(interval);
    }, []);

    async function process() {
        await processQueryParamsState(dispatch, params, {
            statLoading: params => uncorrApi.endpoints.getDatasetStat.initiate(params)(dispatch, store.getState, null),
            empiricLoading: params => uncorrApi.endpoints.getEmpiric.initiate(params)(dispatch, store.getState, null),
            modelLoading: params => uncorrApi.endpoints.getModel.initiate(params)(dispatch, store.getState, null),
        });
        setCompleted(true);
    }

    React.useEffect(() => {
        process();
    }, []);

    return (
        <div style={completed ? undefined : {pointerEvents: 'none', opacity: 0.8}}>
            <InterpolationPage/>
            <div ref={ref}/>
        </div>
    );
};
