import React from 'react';
import {Block} from "../Block/Block";
import {Error} from "../Error/Error";
import {Spinner} from "../Spinner/Spinner";
import './Skeleton.css'

export interface SkeletonProps extends React.PropsWithChildren {
    title: string;
    isError?: boolean;
    errorMessage?: string;
    isFetching?: boolean;
}

export const Skeleton = React.forwardRef<HTMLDivElement, SkeletonProps>((
    {title, isError, errorMessage, isFetching, children},
    ref
) => (
    <Block title={title} disabled={isFetching} ref={ref}>
        {isError
            ? <Error message={errorMessage}/>
            : (
                <>
                    {children}
                    {isFetching && <Spinner className='skeleton_spinner'/>}
                </>
            )
        }
    </Block>
));
