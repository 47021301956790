import React from "react";
import {InterpolationPlot} from "../../widgets/InterpolationPlot";
import {Skeleton} from "../../shared/ui/Skeleton/Skeleton";
import {Field} from "../../shared/types/field";
import useWidthTracker from "../../shared/hooks/useWidthTracker";
import {InterpolationPlotForm} from "./InterpolationPlotForm";
import {useAppSelector} from "../../shared/hooks/store";
import {getInterpolationPlotSettings} from "../../shared/store/InterpolationPlotSettingsSlice";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {DownloadPointsButton} from "./DownloadPointsButton";
import {getDatasetParams} from "../../shared/store/datasetParamsSlice";
import {getMax, getMin} from "../../shared/utils/normalize";
import {CopyLinkOnCurrentPageButton} from "./CopyLinkOnCurrentPageButton";

export interface InterpolationProps {
    useGetQuery: () => {
        isError: boolean,
        isFetching: boolean,
        data?: Field,
    }
}

function prepareColorscaleRanges(ranges: [number, string][], min: number, max: number): [number, string][] {
    if (ranges.length === 0) {
        return ranges;
    }
    ranges = ranges.map(([k, v]) => [k, v.replace(/\s/g, "")]);
    if (min !== max) {
        ranges = ranges.map(([k, v]) => [(k - min) / (max - min), v]);
    }
    ranges = [[0, ranges[0][1]], ...ranges, [1, ranges[ranges.length - 1][1]]];
    return ranges;
}

export function Interpolation(props: InterpolationProps) {
    const {useGetQuery} = props;

    const datasetParams = useAppSelector(getDatasetParams);
    const interpolationPlotSettings = useAppSelector(getInterpolationPlotSettings);

    const [ref, width] = useWidthTracker();

    const {
        isError,
        isFetching,
        data = {field: [[], [], []]},
    } = useGetQuery();

    if (!datasetParams) {
        return null;
    }

    const colorscaleRanges = prepareColorscaleRanges(
        interpolationPlotSettings.colorscaleRanges,
        getMin(data.field[2]),
        getMax(data.field[2])
    );

    return (
        <Skeleton
            title='Result'
            isError={isError}
            errorMessage='Failed to load result'
            isFetching={isFetching}
            ref={ref}
        >
            <InterpolationPlot
                columns={datasetParams.columns}
                points={data.field}
                width={width * 0.8}
                height={width * 0.6}
                {...interpolationPlotSettings}
                colorscale={
                    interpolationPlotSettings.isDefaultColorscale
                        ? interpolationPlotSettings.colorscaleType
                        : colorscaleRanges
                }
            />
            <DownloadPointsButton columns={datasetParams.columns} points={data.field}/>
            <CopyLinkOnCurrentPageButton/>
            <Accordion style={{width: '70%'}}>
                <AccordionSummary>
                    Customize plot
                </AccordionSummary>
                <AccordionDetails>
                    <InterpolationPlotForm/>
                </AccordionDetails>
            </Accordion>
        </Skeleton>
    );
}
