import React from "react";
import {debounce} from "../utils/debounce";


export default function useWidthTracker() {
    const [width, setWidth] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);
    React.useLayoutEffect(() => {
        const onResize = debounce(() => {
            if (ref.current) {
                setWidth(ref.current.clientWidth);
            }
        }, 200);
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [ref, ref.current, setWidth]);
    return [ref, width] as [typeof ref, typeof width];
}
