import React from "react";
import {Block} from "../shared/ui/Block/Block";
import {Form, FormSelect} from "../shared/ui/Form";
import {Methods} from "../shared/types/methods";
import {useAppDispatch, useAppSelector} from "../shared/hooks/store";
import {getMethod, setMethod} from "../shared/store/methodSlice";
import {Option} from "../shared/ui/Form/types";


const METHOD_NAME: Record<Methods, string> = {
    'idw': 'Inverse Distance Weighting',
    'nearest': 'Nearest Neighbour',
    'rbf': 'Radial-basis',
    'linear': 'Linear',
    'simpleKriging': 'Simple Kriging',
    'ordinaryKriging': 'Ordinary Kriging',
    'universalKriging': 'Universal Kriging',
}

export const ChooseMethod = () => {

    const dispatch = useAppDispatch();
    const method = useAppSelector(getMethod);

    const values = React.useMemo(() => {
        if (method === null) {
            return;
        }
        return {methodName: {value: method as string, label: METHOD_NAME[method]}};
    }, [method]);

    const options = React.useMemo(() =>
        Object.entries(METHOD_NAME).map(([k, v]) => ({
                value: k,
                label: v,
            })
        ), []
    );

    const onMethodChange = React.useCallback(({methodName}: {methodName: Option}) => {
        if (methodName.value in METHOD_NAME) {
            dispatch(setMethod(methodName.value as Methods));
        }
    }, []);

    return (
        <Block title="Choose interpolation method">
            <Form onSubmit={onMethodChange} values={values}>
                <FormSelect
                    name='methodName'
                    placeholder='Choose method'
                    options={options}
                    controlStyle={{width: 300}}
                />
            </Form>
        </Block>
    );
};