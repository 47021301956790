import React from "react";
import {Interpolation} from "../../features/interpolation/Interpolation";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/store";
import {getMethod} from "../../shared/store/methodSlice";
import {getDatasetParams} from "../../shared/store/datasetParamsSlice";
import {getEmpiricParams, getModelParams} from "../../shared/store/variogramSlices";
import {
    getIdwParams,
    getLinearParams,
    getNearestNeighbourParams, getOrdinaryKrigingParams,
    getRbfParams, getSimpleKrigingParams, getUniversalKrigingParams
} from "../../shared/store/methodsParamsSlices";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {processQueryParamsState} from "../../shared/utils/processQueryParamsState";
import {getStage} from "../../shared/store/interpolationStageSlice";
import {useQueryParams} from "../../shared/hooks/useQueryParams";
import {Skeleton} from "../../shared/ui/Skeleton/Skeleton";

export const EmbeddedPage = () => {

    const dispatch = useAppDispatch();
    const params = useQueryParams();
    React.useEffect(() => {
        processQueryParamsState(dispatch, params);
    }, []);

    const method = useAppSelector(getMethod);

    const datasetParams = useAppSelector(getDatasetParams);
    const idwParams = useAppSelector(getIdwParams);
    const linearParams = useAppSelector(getLinearParams);
    const nearestParams = useAppSelector(getNearestNeighbourParams);
    const rbfParams = useAppSelector(getRbfParams);

    const empiricParams = useAppSelector(getEmpiricParams);
    const modelParams = useAppSelector(getModelParams);
    const simpleKrigingParams = useAppSelector(getSimpleKrigingParams);
    const ordinaryKrigingParams = useAppSelector(getOrdinaryKrigingParams);
    const universalKrigingParams = useAppSelector(getUniversalKrigingParams);

    const stage = useAppSelector(getStage);

    if (stage < ((method === 'simpleKriging' || method === 'ordinaryKriging' || method === 'universalKriging') ? 5 : 3)) {
        return <Skeleton title='Interpolation plot' isFetching/>;
    }

    const useGetQuery = () => {
        switch (method) {
            case "idw":
                return uncorrApi.useGetIdwQuery({...datasetParams!, ...idwParams!});
            case "linear":
                return uncorrApi.useGetLinearQuery({...datasetParams!, ...linearParams!});
            case "nearest":
                return uncorrApi.useGetNearestNeighbourQuery({...datasetParams!, ...nearestParams!});
            case "rbf":
                return uncorrApi.useGetRbfQuery({...datasetParams!, ...rbfParams!});
            case "simpleKriging":
                return uncorrApi.useGetSimpleKrigingQuery({...datasetParams!, ...empiricParams!, ...modelParams!, ...simpleKrigingParams!});
            case "ordinaryKriging":
                return uncorrApi.useGetSimpleKrigingQuery({...datasetParams!, ...empiricParams!, ...modelParams!, ...ordinaryKrigingParams!});
            case "universalKriging":
                return uncorrApi.useGetSimpleKrigingQuery({...datasetParams!, ...empiricParams!, ...modelParams!, ...universalKrigingParams!});
            default:
                return {isError: true, isFetching: false};
        }
    }

    return <Interpolation useGetQuery={useGetQuery}/>;
};