import React from "react";
import './Header.css';
import classNames from "classnames";
import Icon from '../../shared/asset/img/forward-arrow-25.png';
import {Link} from "react-router-dom";


export interface HeaderProps {
    className?: string;
}

export const Header: React.FC<HeaderProps> = ({className}) => (
    <header className={classNames(className, 'header')}>
        <Link to='/api/docs' target='_blank' className='header_api header_link'>
            API
            <img src={Icon} alt='forward arrow'/>
        </Link>
        <Link to="/" className="header_link">
            <p className='header_title-logo'>Uncorr</p>
            <p className='header_title-degree'>v2.0</p>
        </Link>
        <Link to='/docs' className='header_docs header_link'>
            Docs
        </Link>
    </header>
);
