import React from "react";
import './Table.css';
import {round} from "../../utils/round";

export interface TableProps {
    headers: string[];
    columns?: string[];
    points: Array<Array<number | string>>;
}

export const Table = ({headers, columns, points}: TableProps) => {
    if (!points.length || !points[0].length) {
        return null;
    }
    const n = points.length, m = points[0].length;

    return (
        <table className='table'>
            <thead>
                <tr className='table_header'>
                    {columns && <th className='table_cell'/>}
                    {headers.map((header, i) => (
                        <th key={i} className='table_cell __emphasized'>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {[...Array(m).keys()].map(j => (
                    <tr key={j + 1} className='table_row'>
                        {columns && (
                            <td className='table_cell __emphasized'>{columns[j]}</td>
                        )}
                        {[...Array(n).keys()].map(i => (
                            <td key={i} className='table_cell'>
                                {typeof points[i][j] == 'number'
                                    ? round(points[i][j] as number, 5)
                                    : points[i][j]
                                }
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
};
