import {useFormContext, Controller, FieldValues, FieldPath} from "react-hook-form";
import {ColorPicker} from "../ColorPicker/ColorPicker";

export interface FormColorPickerProps<V extends FieldValues> {
    name: FieldPath<V>
    required?: boolean
    disabled?: boolean
}

export function FormColorPicker<V extends FieldValues>(props: FormColorPickerProps<V>) {
    const {name, required, disabled} = props;
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            rules={{required}}
            control={control}
            render={({field}) => (
                <ColorPicker {...field} disabled={disabled}/>
            )}
        />
    );
}