import {BasePlot} from "../../shared/types/BasePlot";

export interface HistogramPlotProps {
    counts: number[]
    binEdges: number[]
    nPoints: number
    width: number
    height: number
}

export const HistogramPlot = ({counts, binEdges, nPoints, width, height}: HistogramPlotProps) => {

    const binCenters = Array(binEdges.length - 1);
    const binWidths = Array(binEdges.length - 1);
    for (let i = 0; i < binEdges.length - 1; i++) {
        binCenters[i] = (binEdges[i] + binEdges[i + 1]) / 2
        binWidths[i] = (binEdges[i] - binEdges[i + 1]) * 0.95;
    }

    const cumulativeCounts = Array(counts.length + 1);
    cumulativeCounts[0] = 0;
    for (let i = 0; i < counts.length; i++) {
        cumulativeCounts[i + 1] = (cumulativeCounts[i] + counts[i]);
    }
    const percentileCumulativeCounts = cumulativeCounts.map(c => (c / nPoints) * 100);


    return (
        <BasePlot
            data={[
                {
                    name: 'Histogram',
                    type: 'bar',
                    x: binCenters,
                    y: counts,
                    width: binWidths,
                    text: counts.map(String),
                },
                {
                    name: 'Cumulative count',
                    type: 'scatter',
                    mode: 'lines+markers',
                    x: binEdges,
                    y: percentileCumulativeCounts,
                    yaxis: 'y2',
                }
            ]}
            defaultLayout={{
                title: 'Histogram plot',
                xaxis: {title: 'Bins', exponentformat: 'none'},
                yaxis: {title: 'Counts', exponentformat: 'none'},
                yaxis2: {
                    title: 'Percentile',
                    overlaying: 'y',
                    anchor: 'y',
                    rangemode: 'tozero',
                    side: 'right'
                },
                showlegend: false,
            }}
            layout={{
                width,
                height,
            }}
        />
    );
};
