import * as React from 'react';
import {useAppSelector} from "./store";
import {getDatasetParams} from "../store/datasetParamsSlice";
import {getMethod} from "../store/methodSlice";
import {
    getIdwParams,
    getLinearParams,
    getNearestNeighbourParams, getOrdinaryKrigingParams,
    getRbfParams,
    getSimpleKrigingParams, getUniversalKrigingParams
} from "../store/methodsParamsSlices";
import {getEmpiricParams, getModelParams} from "../store/variogramSlices";
import {Methods} from "../types/methods";

export function useCurrentStateParams() {
    const datasetParams = useAppSelector(getDatasetParams);
    const method = useAppSelector(getMethod);

    const empiricParams = useAppSelector(getEmpiricParams);
    const modelParams = useAppSelector(getModelParams);

    const idwParams = useAppSelector(getIdwParams);
    const rbfParams = useAppSelector(getRbfParams);
    const linearParams = useAppSelector(getLinearParams);
    const nearestParams = useAppSelector(getNearestNeighbourParams);
    const simpleKrigingParams = useAppSelector(getSimpleKrigingParams);
    const ordinaryKrigingParams = useAppSelector(getOrdinaryKrigingParams);
    const universalKrigingParams = useAppSelector(getUniversalKrigingParams);

    function toObj(obj: object | null) {
        if (obj === null) {
            return {};
        }
        return Object.fromEntries(
            Object.entries(obj)
                .filter(entry => entry[1] !== undefined && entry[1] !== null)
        );
    }

    function createMethodParams(method: Methods) {
        switch (method) {
            case "nearest":
                return toObj(nearestParams);
            case "linear":
                return toObj(linearParams);
            case "idw":
                return toObj(idwParams);
            case "rbf":
                return toObj(rbfParams);
            case "ordinaryKriging":
                return toObj(ordinaryKrigingParams);
            case "simpleKriging":
                return toObj(simpleKrigingParams);
            case "universalKriging":
                return toObj(universalKrigingParams);
        }
    }

    return {
        ...(datasetParams && {
            filename: datasetParams.filename,
            columns: datasetParams.columns.join(','),
            normalized: Boolean(datasetParams.normalized) + "",
        }),
        ...(method && {method}),
        ...(empiricParams && toObj(empiricParams)),
        ...(modelParams && toObj(modelParams)),
        ...(method && createMethodParams(method)),
    };
}

export const useLinkOnCurrentState = () => {
    const params = useCurrentStateParams();
    const domain = React.useMemo(() => window ? window.location.hostname : "uncorr.com", [window]);
    return domain + "/cmd?" + Object.entries(params).map(entry => entry.join('=')).join('&');
};
