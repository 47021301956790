import React from "react";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {InterpolatorProps} from "../../shared/types/interpolator";
import {getNearestNeighbourParams, setNearestNeighbourParams} from "../../shared/store/methodsParamsSlices";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/store";
import {getStage} from "../../shared/store/interpolationStageSlice";
import {Block} from "../../shared/ui/Block/Block";
import {Interpolation} from "./Interpolation";
import {CallbackProps, InterpolationForm} from "../InterpolationForm";

export const NearestNeighbour = ({datasetParams}: InterpolatorProps) => {

    const dispatch = useAppDispatch();
    const nearestNeighbourParams = useAppSelector(getNearestNeighbourParams);
    const stage = useAppSelector(getStage);

    const onSubmit = React.useCallback((props: CallbackProps) => {
        dispatch(setNearestNeighbourParams(props));
    }, []);

    return (
        <>
            <Block title='Customize interpolation'>
                <InterpolationForm onSubmit={onSubmit} defaultValues={nearestNeighbourParams ?? undefined}/>
            </Block>
            {stage >= 3 && nearestNeighbourParams && (
                <Interpolation useGetQuery={() => uncorrApi.useGetNearestNeighbourQuery({...datasetParams, ...nearestNeighbourParams})}/>
            )}
        </>
    );
};
