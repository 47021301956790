import * as React from 'react';
import {CardGrid} from "../../shared/ui/CardGrid/CardGrid";
import {Scroll} from "../../shared/ui/Scroll/Scroll";
import {Block} from "../../shared/ui/Block/Block";
import {Link} from "react-router-dom";

import variogram from "../../shared/asset/img/variogram.png";
import stage1 from "../../shared/asset/img/stage_1.png";
import stage2 from "../../shared/asset/img/stage_2.png";
import stage3 from "../../shared/asset/img/stage_3.png";
import stage4 from "../../shared/asset/img/stage_4.png";
import stage5 from "../../shared/asset/img/stage_5.png";
import stage6 from "../../shared/asset/img/stage_6.png";
import stage7 from "../../shared/asset/img/stage_7.png";

import './DocsPage.css';

const COMMON_PARAMETERS = [
    ['filename', '[Required] Active dataset hash. You can get it as a result of uploading file.'],
    ['columns', '[Required] Sequence of column names from dataset divided by ",". They are interpreted as "X,Y,Z".'],
    ['normalized', '"true" or "false". Default is "false". Should X and Y axes be normalized by formula `x_i = (x_i - X.mean()) / X.std()`'],
    ['method', '[Required] One of "idw" | "nearest" | "rbf" | "linear" | "simpleKriging" | "ordinaryKriging" | "universalKriging". Interpolation method'],
    ['xMin', 'Number. Default is X.min(). Minimum value of X axis for calculating result points.'],
    ['xMax', 'Number. Default is X.max(). Maximum value of X axis for calculating result points.'],
    ['yMin', 'Number. Default is Y.min(). Minimum value of Y axis for calculating result points.'],
    ['yMax', 'Number. Default is Y.max(). Maximum value of Y axis for calculating result points.'],
];

const IDW_PARAMETERS = [
    ['p', 'Number. Default is 2. Degree of inverse weighting.'],
    ['k', 'Number. Default is 10. Maximum number of nearest neighbors considered in calculation for each point.'],
    ['r', 'Number. Default is 1000. Maximum radius where points considered in calculation for each point.'],
];

const RBF_PARAMETERS = [
    ['kernel', 'One of "linear" | "thin_plate_spline" | "cubic". Default is "cubic". Kernel type.'],
    ['neighbors', 'Integer. Default is 100. Maximum number of nearest neighbors considered in calculation for each point.'],
    ['smoothing', 'Float. Default is 0. Smoothing parameter.'],
    ['degree', 'Integer. Default is 1. Degree of the added polynomial.'],
];

const KRIGING_PARAMETERS = [
    ['modelName', '[Required] One of "linear" | "gaussian" | "exponential" | "matern" | "integral" | "stable" | "rational" | "cubic" | "circular" | "spherical". Covariance model type.'],
    ['var', 'Float. Default is 1. Variance of covariance model.'],
    ['range', 'Float. Default is 1. Range of covariance model.'],
    ['nugget', 'Float. Default is 0. Nugget of covariance model.'],
];

const UNIVERSAL_KRIGING_PARAMETERS = [
    ['driftDegree', 'Number. Default is 1. Degree of drift in '],
];

const ParameterTable = ({body}: { body: string[][] }) => (
    <table className='docs_table'>
        <thead className='docs_head'>
        <tr>
            <td className='docs_table-cell __first'>Parameter</td>
            <td className='docs_table-cell'>Description</td>
        </tr>
        </thead>
        <tbody>
        {body.map(x => (
            <tr key={x[0]}>
                <td className='docs_table-cell'>{x[0]}</td>
                <td className='docs_table-cell'>{x[1]}</td>
            </tr>
        ))}
        </tbody>
    </table>
);

export const DocsPage = () => {
    return (
        <Scroll>
            <CardGrid>
                <Block title='Documentation'>
                    <div className='content'>
                        <h1 className='docs_title'>
                            Welcome to Uncorr - tool for creating awesome interpolation plots!
                        </h1>
                        <div className='description'>
                            <article className='description_article'>
                                <h3 className='docs_title'>What do we have here?</h3>
                                <p>
                                    Uncorr is a tool with many use cases.
                                    You can process datasets, calculate statistics, interpolate and make customizable
                                    plots.
                                    Also you can use API for integrating with other services or interactively embed
                                    Uncorr "as is" in your website.
                                    Service has dataset file limit 20mb. It is enough for most cases, but may be changed
                                    by request.
                                    All plots can be customized and downloaded in svg format.
                                </p>
                            </article>
                            <article>
                                <h3 className='docs_title'>Ordinary working flow</h3>
                                <ul>
                                    <li>
                                        <h5 className='docs_title __sub'>Upload dataset</h5>
                                        <p>
                                            Everything in Uncorr is about data. So firstly you have to upload a dataset.
                                            You can click on a "Browse file" block or simply drug and drop your file in
                                            it.
                                            Alternatively, if you are learning Uncorr you might "choose sample dataset",
                                            so you will see already uploaded Propane dataset.
                                            <img src={stage1} alt="stage 1" className='docs_img'/>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Choose dataset preprocessing params</h5>
                                        <p>
                                            After uploading you will see a form where you can do make a simple
                                            preprocessing.
                                            By default axes are picked in a order they appear in dataset.
                                            So X-axis is the first column, Y-axis is the second and Z-axis is the third.
                                            But you can choose preferred order.
                                            Also mean-std normalization of X, Y axes is available.
                                            <img src={stage2} alt="stage 2" className='docs_img'/>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Investigate statistical data and plots</h5>
                                        <p>
                                            Then you might be interested in dataset structure.
                                            Use preview, statistics, points plot and histogram plot to make basic
                                            assumptions.
                                            Also if your data is represented in geographical coordinates you can look at
                                            map-points plot.
                                            Just pick the correct coordinate system. UTM with time zone and lat-lon are
                                            currently supported.
                                            <img src={stage3} alt="stage 3" className='docs_img'/>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Choose interpolation method</h5>
                                        <p>
                                            Interpolation methods are different in terms of constraints,
                                            time complexity and accuracy. See detailed review and comparison below.
                                            <img src={stage4} alt="stage 4" className='docs_img'/>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>[Kriging only] Choose covariance model and params</h5>
                                        <p>
                                            If you chose any of kriging method, you need to provide a covariance model.
                                            In Uncorr it is defined by a model name and three parameters: variance,
                                            range and nugget. See <a
                                            href="https://geostat-framework.readthedocs.io/projects/gstools/en/stable/api/gstools.covmodel.html">
                                                gstools.covmodel
                                            </a>
                                            <br/>Here: variance = sill - nugget
                                            <img src={variogram} alt="embedded Uncorr" className='docs_img'/>
                                            <img src={stage5} alt="stage 5" className='docs_img'/>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Set interpolation bounds</h5>
                                        <p>
                                            The last form contains specific method parameters (if they exist)
                                            and bounds for calculating result interpolation.
                                            <img src={stage6} alt="stage 6" className='docs_img'/>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Investigate and customize interpolation plot</h5>
                                        <p>
                                            If did everything right, you will see interpolation plot.
                                            Additional settings are provided after clicking "Customize plot" button
                                            below the plot.
                                            <img src={stage7} alt="stage 7" className='docs_img'/>
                                        </p>
                                    </li>
                                </ul>
                            </article>
                            <article>
                                <h3 className='docs_title'>Interpolation methods</h3>
                                <ul>
                                    <li>
                                        <h5 className='docs_title __sub'>Common parameters</h5>
                                        <ParameterTable body={COMMON_PARAMETERS}/>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Inverse Distance Weighting</h5>
                                        <p>
                                            See <a href='https://en.wikipedia.org/wiki/Inverse_distance_weighting'>
                                                Inverse distance weighting interpolation
                                            </a>
                                        </p>
                                        <ParameterTable body={IDW_PARAMETERS}/>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Nearest Neighbour</h5>
                                        <p>
                                            See <a href='https://en.wikipedia.org/wiki/Nearest-neighbor_interpolation'>
                                                Nearest Neighbour interpolation
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Radial-basis function</h5>
                                        <p>
                                            See <a href='https://en.wikipedia.org/wiki/Radial_basis_function_interpolation'>
                                                Radial-basis function interpolation
                                            </a>
                                        </p>
                                        <ParameterTable body={RBF_PARAMETERS}/>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Linear</h5>
                                        <p>
                                            See <a href='https://en.wikipedia.org/wiki/Linear_interpolation'>
                                                Linear interpolation
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Simple and Ordinary Kriging</h5>
                                        <p>
                                            See <a href='https://en.wikipedia.org/wiki/Kriging'>
                                                Kriging
                                            </a> and <a
                                            href='https://geostat-framework.readthedocs.io/projects/gstools/en/stable/api/gstools.covmodel.html#'/>
                                        </p>
                                        <ParameterTable body={KRIGING_PARAMETERS}/>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Universal Kriging</h5>
                                        <p>
                                            It is a kriging method like simple or ordinary but using another
                                            assumptions.
                                            See <a href='https://en.wikipedia.org/wiki/Regression-kriging'>
                                                Universal kriging
                                            </a>
                                        </p>
                                        <ParameterTable body={UNIVERSAL_KRIGING_PARAMETERS}/>
                                    </li>
                                </ul>
                            </article>
                            <article>
                                <h3 className='docs_title'>Integration</h3>
                                <p>There are a few ways to integrate Uncorr in other website</p>
                                <ul>
                                    <li>
                                        <h5 className='docs_title __sub'>Use API</h5>
                                        <p>
                                            Preferred method when only calculations and no visuals needed.
                                            See <Link to={'/api/docs'}>docs page</Link>
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Use iframe</h5>
                                        <p>
                                            Embed interpolation plot in any web page with iframe.
                                            Use link "uncorr.com/embedded" with query parameters (if needed)
                                        </p>
                                    </li>
                                    <li>
                                        <h5 className='docs_title __sub'>Generate link</h5>
                                        <p>
                                            Create link on certain Uncorr page - it will be automatically calculated
                                            when opened.
                                            Use link "uncorr.com/cmd" + same parameters as in iframe method
                                        </p>
                                    </li>
                                </ul>
                            </article>
                            <article>
                                <h3 className='docs_title'>Credits</h3>
                                <p>Developed by</p>
                                <p>
                                    <a className='credits_link' target="_blank" href='https://github.com/rytuo'>
                                        Popov Aleksandr
                                    </a>
                                    {' and '}
                                    <a className='credits_link' target="_blank" href='https://sudo.team/'>
                                        SUDO team
                                    </a>
                                </p>
                            </article>
                        </div>
                    </div>
                </Block>
            </CardGrid>
        </Scroll>
    )
        ;
};
