import React from "react";
import {getUniversalKrigingParams, setUniversalKrigingParams} from "../../../shared/store/methodsParamsSlices";
import {InterpolatorProps} from "../../../shared/types/interpolator";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/store";
import {getStage} from "../../../shared/store/interpolationStageSlice";
import {Block} from "../../../shared/ui/Block/Block";
import {FormGroup, FormLabel} from "../../../shared/ui/Form";
import {Interpolation} from "../Interpolation";
import {uncorrApi} from "../../../shared/service/uncorrApi";
import {getEmpiricParams, getModelParams} from "../../../shared/store/variogramSlices";
import {Variogram} from "./Variogram";
import {CallbackProps, InterpolationForm} from "../../InterpolationForm";
import {FormNumberInput} from "../../../shared/ui/Form/FormNumberInput";

export interface UniversalKrigingFormCallback extends CallbackProps {
    driftDegree?: number
}

export const UniversalKriging = ({datasetParams}: InterpolatorProps) => {

    const dispatch = useAppDispatch();
    const universalKrigingParams = useAppSelector(getUniversalKrigingParams);
    const empiricParams = useAppSelector(getEmpiricParams);
    const modelParams = useAppSelector(getModelParams);
    const stage = useAppSelector(getStage);

    const onSubmit = React.useCallback((props: UniversalKrigingFormCallback) => {
        dispatch(setUniversalKrigingParams({ ...props }));
    }, []);

    return (
        <>
            <Variogram datasetParams={datasetParams}/>
            {stage >= 4 && (
                <Block title='Customize interpolation'>
                    <InterpolationForm onSubmit={onSubmit} defaultValues={universalKrigingParams ?? undefined}>
                        <FormGroup>
                            <FormLabel text='Drift'/>
                            <FormNumberInput
                                name='driftDegree'
                                placeholder='1'
                            />
                        </FormGroup>
                    </InterpolationForm>
                </Block>
            )}
            {stage >= 5 && empiricParams && modelParams && universalKrigingParams && (
                <Interpolation useGetQuery={() => uncorrApi.useGetUniversalKrigingQuery({...datasetParams, ...empiricParams, ...modelParams, ...universalKrigingParams})}/>
            )}
        </>
    );
};
