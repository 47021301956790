import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {DatasetParams, DatasetStat} from "../types/dataset";
import {Field} from "../types/field";
import {EmpiricParams, ModelParams, ModelResponse} from "../types/variogram";
import {NearestNeighbourParams} from "../types/params/nearestNeighbour";
import {IdwParams} from "../types/params/idw";
import {LinearParams} from "../types/params/linear";
import {RbfParams} from "../types/params/rbf";
import {OrdinaryKrigingParams} from "../types/params/kriging/ordinary";
import {UniversalKrigingParams} from "../types/params/kriging/universal";
import {filterNullsAndUndefined} from "../utils/filter";
import {incRevision} from "../store/InterpolationPlotSettingsSlice";
import {SimpleKrigingParams} from "../types/params/kriging/simple";

export const uncorrApi = createApi({
    reducerPath: "uncorrApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/",
        paramsSerializer: params => new URLSearchParams(filterNullsAndUndefined(params)).toString(),
        timeout: 120 * 1000, // 120s
    }),
    endpoints: builder => ({
        uploadFile: builder.mutation<DatasetParams, File>({
            query: (file) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: 'file/upload',
                    method: 'POST',
                    body: formData,
                }
            },
        }),
        chooseSampleFile: builder.mutation<DatasetParams, any>({
            query: () => ({
                url: 'file/sample',
                method: 'POST',
            }),
        }),
        getDatasetStat: builder.query<DatasetStat, DatasetParams>({
            query: (req) => ({
                url: 'file/stat',
                params: req,
            }),
        }),
        getEmpiric: builder.query<Field, DatasetParams & EmpiricParams>({
            query: (req) => ({
                url: 'variogram/empiric',
                params: req,
            }),
        }),
        getModel: builder.query<ModelResponse, DatasetParams & EmpiricParams & ModelParams>({
            query: (req) => ({
                url: 'variogram/model',
                params: req,
            }),
        }),
        getNearestNeighbour: builder.query<Field, DatasetParams & NearestNeighbourParams>({
            query: (req) => ({
                url: 'interpolate/nearest',
                params: req,
            }),
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                await queryFulfilled;
                dispatch(incRevision());
            },
        }),
        getIdw: builder.query<Field, DatasetParams & IdwParams>({
            query: (req) => ({
                url: 'interpolate/idw',
                params: req,
            }),
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                await queryFulfilled;
                dispatch(incRevision());
            },
        }),
        getLinear: builder.query<Field, DatasetParams & LinearParams>({
            query: (req) => ({
                url: 'interpolate/linear',
                params: req,
            }),
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                await queryFulfilled;
                dispatch(incRevision());
            },
        }),
        getRbf: builder.query<Field, DatasetParams & RbfParams>({
            query: (req) => ({
                url: 'interpolate/rbf',
                params: req,
            }),
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                await queryFulfilled;
                dispatch(incRevision());
            },
        }),
        getSimpleKriging: builder.query<Field, DatasetParams & EmpiricParams & ModelParams & SimpleKrigingParams>({
            query: (req) => ({
                url: 'interpolate/kriging/simple',
                params: req,
            }),
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                await queryFulfilled;
                dispatch(incRevision());
            },
        }),
        getOrdinaryKriging: builder.query<Field, DatasetParams & EmpiricParams & ModelParams & OrdinaryKrigingParams>({
            query: (req) => ({
                url: 'interpolate/kriging/ordinary',
                params: req,
            }),
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                await queryFulfilled;
                dispatch(incRevision());
            },
        }),
        getUniversalKriging: builder.query<Field, DatasetParams & EmpiricParams & ModelParams & UniversalKrigingParams>({
            query: (req) => ({
                url: 'interpolate/kriging/universal',
                params: req,
            }),
            onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
                await queryFulfilled;
                dispatch(incRevision());
            },
        }),
    }),
});
