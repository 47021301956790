import React from "react";
import './FileInput.css';
import {Button} from "../Button/Button";
import classNames from "classnames";

export interface FileUploadProps {
    file?: File;
    onSubmit: (file: File) => void;
}

export const FileInput = (({file, onSubmit}: FileUploadProps) => {

    const [highlighted, setHighlighted] = React.useState(false);

    const ref = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (ref.current) {
            const dt = new DataTransfer()
            if (file) {
                dt.items.add(file)
            }
            ref.current.files = dt.files;
        }
    }, [ref, file]);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        e => onSubmit(e.target.files![0]),
        [onSubmit]
    );

    const updateHighlighted: React.DragEventHandler = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === 'dragenter' || e.type === 'dragover') {
                setHighlighted(true);
            } else if (e.type === 'dragleave') {
                setHighlighted(false);
            }
        },
        []
    );

    const onDrop: React.DragEventHandler = React.useCallback(
        e => {
            e.preventDefault();
            e.stopPropagation();
            setHighlighted(false);
            if (e.dataTransfer &&
                e.dataTransfer.files &&
                e.dataTransfer.files.length > 0) {
                onSubmit(e.dataTransfer.files[0]);
            }
        },
        [onSubmit]
    );

    return (
        <label
            className={classNames('file-input', highlighted && '__highlighted')}
            draggable
            onDragEnter={updateHighlighted}
            onDragOver={updateHighlighted}
            onDragLeave={updateHighlighted}
            onDrop={onDrop}
        >
            <input ref={ref} type="file" name="file" onChange={onChange} hidden/>
            {highlighted
                ? (
                    <p className='file-input_text'>Upload file</p>
                )
                : (
                    <>
                        <p>{file?.name || 'No file chosen'}</p>
                        <p className='file-input_text'>Drag and drop your file here or</p>
                        <Button type='button' className='file-input_button'>
                            Browse file
                        </Button>
                    </>
                )
            }
        </label>
    );
})
