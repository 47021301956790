import {configureStore} from '@reduxjs/toolkit';
import {uncorrApi} from "../service/uncorrApi";
import {setupListeners} from "@reduxjs/toolkit/query";
import {methodSlice} from "./methodSlice";
import {interpolationStageSlice} from "./interpolationStageSlice";
import {datasetParamsSlice} from "./datasetParamsSlice";
import {empiricParamsSlice, modelParamsSlice} from "./variogramSlices";
import {
    idwParamsSlice,
    nearestNeighbourParamsSlice,
    linearParamsSlice,
    rbfParamsSlice,
    simpleKrigingParamsSlice,
    ordinaryKrigingParamsSlice,
    universalKrigingParamsSlice,
} from "./methodsParamsSlices";
import {interpolationPlotSettingsSlice} from "./InterpolationPlotSettingsSlice";

export const store = configureStore({
    reducer: {
        [uncorrApi.reducerPath]: uncorrApi.reducer,
        [interpolationStageSlice.name]: interpolationStageSlice.reducer,
        [datasetParamsSlice.name]: datasetParamsSlice.reducer,
        [methodSlice.name]: methodSlice.reducer,

        // simple methods
        [idwParamsSlice.name]: idwParamsSlice.reducer,
        [rbfParamsSlice.name]: rbfParamsSlice.reducer,
        [linearParamsSlice.name]: linearParamsSlice.reducer,
        [nearestNeighbourParamsSlice.name]: nearestNeighbourParamsSlice.reducer,

        // for kriging
        [simpleKrigingParamsSlice.name]: simpleKrigingParamsSlice.reducer,
        [empiricParamsSlice.name]: empiricParamsSlice.reducer,
        [modelParamsSlice.name]: modelParamsSlice.reducer,

        // kriging methods
        [ordinaryKrigingParamsSlice.name]: ordinaryKrigingParamsSlice.reducer,
        [universalKrigingParamsSlice.name]: universalKrigingParamsSlice.reducer,

        [interpolationPlotSettingsSlice.name]: interpolationPlotSettingsSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(uncorrApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;
