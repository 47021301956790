import {FileInput} from "../../shared/ui/FileInput/FileInput";
import {Block} from "../../shared/ui/Block/Block";
import React from "react";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {Error} from "../../shared/ui/Error/Error";
import {clearDatasetParams, setDatasetParams} from "../../shared/store/datasetParamsSlice";
import {useAppDispatch} from "../../shared/hooks/store";
import {Spinner} from "../../shared/ui/Spinner/Spinner";
import {Button} from "../../shared/ui/Button/Button";

export const FileUpload = () => {
    const dispatch = useAppDispatch();
    const [uploadFile, fileStatus] = uncorrApi.useUploadFileMutation();
    const [setSample, sampleStatus] = uncorrApi.useChooseSampleFileMutation();
    const [file, setFile] = React.useState<File>();

    const onFileChange = React.useCallback((new_file?: File) => {
        if (new_file === file) {
            return;
        }
        setFile(new_file);
        dispatch(clearDatasetParams());
        if (new_file) {
            uploadFile(new_file).then(res => {
                if ('data' in res) {
                    dispatch(setDatasetParams(res.data));
                }
            });
        }
    }, [file]);

    const onChooseSample = React.useCallback(() => {
            setFile(undefined);
            dispatch(clearDatasetParams());
            setSample('sample').then(res => {
                if ('data' in res) {
                    dispatch(setDatasetParams(res.data));
                }
            });
        }, []
    );

    const isLoading = fileStatus.isLoading || sampleStatus.isLoading;
    const isError = fileStatus.isError || sampleStatus.isError;

    return (
        <Block title='Upload file with dataset' disabled={isLoading}>
            <FileInput file={file} onSubmit={onFileChange}/>
            {isLoading && <Spinner/>}
            {isError && <Error message='Error while loading file'/>}
            <p>Also you can</p>
            <div>
                <Button onClick={onChooseSample} type='button'>
                    Choose sample dataset
                </Button>
            </div>
        </Block>
    );
}