import {createSlice, PayloadAction} from "@reduxjs/toolkit";

function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export default function createBaseSlice<State, Name extends string>(name: Name, initialState: State) {
    return createSlice({
        name: name,
        initialState,
        reducers: {
            ['set' + capitalizeFirstLetter(name)]: (state, action: PayloadAction<State>) =>
                action.payload,
        },
    });
}
