import React from "react";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {InterpolatorProps} from "../../shared/types/interpolator";
import {getIdwParams, setIdwParams} from "../../shared/store/methodsParamsSlices";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/store";
import {getStage} from "../../shared/store/interpolationStageSlice";
import {Block} from "../../shared/ui/Block/Block";
import {Interpolation} from "./Interpolation";
import {CallbackProps, InterpolationForm} from "../InterpolationForm";
import {FormNumberInput} from "../../shared/ui/Form/FormNumberInput";
import {FormGroup, FormLabel} from "../../shared/ui/Form";

interface IdwFormParams extends CallbackProps {
    p?: number
    k?: number
    r?: number
}

export const Idw = ({datasetParams}: InterpolatorProps) => {

    const dispatch = useAppDispatch();
    const idwParams = useAppSelector(getIdwParams);
    const stage = useAppSelector(getStage);

    const onSubmit = React.useCallback((params: IdwFormParams) => {
        dispatch(setIdwParams(params));
    }, []);

    return (
        <>
            <Block title='Customize interpolation'>
                <InterpolationForm
                    defaultValues={idwParams ?? undefined}
                    onSubmit={onSubmit}
                >
                    <FormGroup>
                        <FormLabel text='p'/>
                        <FormNumberInput name='p' placeholder='2'/>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel text='k'/>
                        <FormNumberInput name='k' placeholder='10'/>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel text='r'/>
                        <FormNumberInput name='r' placeholder='1000'/>
                    </FormGroup>
                </InterpolationForm>
            </Block>
            {stage >= 3 && idwParams && (
                <Interpolation useGetQuery={() => uncorrApi.useGetIdwQuery({...datasetParams, ...idwParams})}/>
            )}
        </>
    );
};
