import {EmpiricParams, ModelParams} from "../types/variogram";
import {RootState} from "./store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const empiricParamsSlice = createSlice({
    name: 'empiricParams',
    initialState: null as EmpiricParams | null,
    reducers: {
        setEmpiricParams: (state, action: PayloadAction<EmpiricParams>) => action.payload,
    },
});

export const { setEmpiricParams } = empiricParamsSlice.actions;
export const getEmpiricParams = (state: RootState) => state.empiricParams;


export const modelParamsSlice = createSlice({
    name: 'modelParams',
    initialState: null as ModelParams | null,
    reducers: {
        setModelParams: (state, action: PayloadAction<ModelParams>) => action.payload,
    },
});

export const { setModelParams } = modelParamsSlice.actions;
export const getModelParams = (state: RootState) => state.modelParams;
