import {setDatasetParams} from "../store/datasetParamsSlice";
import {setMethod} from "../store/methodSlice";
import {Methods} from "../types/methods";
import {
    setIdwParams,
    setLinearParams,
    setNearestNeighbourParams, setOrdinaryKrigingParams,
    setRbfParams,
    setSimpleKrigingParams, setUniversalKrigingParams
} from "../store/methodsParamsSlices";
import {Kernel} from "../types/params/rbf";
import {setEmpiricParams, setModelParams} from "../store/variogramSlices";
import {DatasetParams} from "../types/dataset";
import {EmpiricParams, ModelParams} from "../types/variogram";
import {AppDispatch} from "../store/store";


function extractNumber(p?: string) {
    return p ? Number(p) : undefined;
}

export interface processQueryParamsStateProps {
    statLoading?: (params: DatasetParams) => Promise<any>;
    empiricLoading?: (params: DatasetParams & EmpiricParams) => Promise<any>;
    modelLoading?: (params: DatasetParams & EmpiricParams & ModelParams) => Promise<any>;
}

export async function processQueryParamsState(
    dispatch: AppDispatch,
    params: Record<string, string | undefined>,
    props?: processQueryParamsStateProps
) {
    if (!(params['filename'] && params['columns'])) {
        return;
    }
    const datasetParams = {
        filename: params['filename'],
        columns: params['columns'].split(','),
        normalized: params['normalized'] === 'true',
    };
    dispatch(setDatasetParams(datasetParams));

    await props?.statLoading?.call(null, datasetParams);

    if (!params['method']) {
        return;
    }
    dispatch(setMethod(params['method'] as Methods));

    const base = {
        xMin: extractNumber(params['xMin']),
        xMax: extractNumber(params['xMax']),
        yMin: extractNumber(params['yMin']),
        yMax: extractNumber(params['yMax']),
    };

    switch (params['method']) {
        case 'idw':
            dispatch(setIdwParams({
                ...base,
                p: extractNumber(params['p']),
                k: extractNumber(params['k']),
                r: extractNumber(params['r']),
            }));
            break;
        case 'nearest':
            dispatch(setNearestNeighbourParams(base));
            break;
        case 'rbf':
            dispatch(setRbfParams({
                ...base,
                neighbors: extractNumber(params['neighbors']),
                smoothing: extractNumber(params['smoothing']),
                degree: extractNumber(params['degree']),
                kernel: params['kernel'] as Kernel,
            }));
            break;
        case 'linear':
            dispatch(setLinearParams(base));
            break;
        case 'simpleKriging':
        case 'ordinaryKriging':
        case 'universalKriging':
            const empiricParams = {};
            dispatch(setEmpiricParams(empiricParams));
            await props?.empiricLoading?.call(null, {...datasetParams, ...empiricParams});

            if (!params['modelName']) {
                return;
            }
            const modelParams = {
                modelName: params['modelName'],
                'var': extractNumber(params['var']),
                'range': extractNumber(params['range']),
                'nugget': extractNumber(params['nugget']),
            };
            dispatch(setModelParams(modelParams));
            await props?.modelLoading?.call(null, {...datasetParams, ...empiricParams, ...modelParams});


            switch (params['method']) {
                case 'simpleKriging':
                    dispatch(setSimpleKrigingParams(base));
                    break;
                case 'ordinaryKriging':
                    dispatch(setOrdinaryKrigingParams(base));
                    break;
                case 'universalKriging':
                    dispatch(setUniversalKrigingParams({
                        ...base,
                        driftDegree: extractNumber(params['driftDegree']),
                    }));
                    break;
            }
            break;
    }
}