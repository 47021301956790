import {VariogramPlot} from "../../../widgets/VariogramPlot";
import {Skeleton} from "../../../shared/ui/Skeleton/Skeleton";
import {Form, FormGroup, FormLabel, FormSelect} from "../../../shared/ui/Form";
import {DatasetParams} from "../../../shared/types/dataset";
import {uncorrApi} from "../../../shared/service/uncorrApi";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/store";
import useWidthTracker from "../../../shared/hooks/useWidthTracker";
import {
    getEmpiricParams,
    getModelParams,
    setEmpiricParams,
    setModelParams
} from "../../../shared/store/variogramSlices";
import {getStage} from "../../../shared/store/interpolationStageSlice";
import {FormNumberInput} from "../../../shared/ui/Form/FormNumberInput";
import {Option} from "../../../shared/ui/Form/types";

export interface VariogramProps {
    datasetParams: DatasetParams;
}

export interface ModelFormCallback {
    modelName?: Option
    var?: number
    range?: number
    nugget?: number
}

const modelOptions = [
    {value: 'linear', label: 'Linear'},
    {value: 'gaussian', label: 'Gaussian'},
    {value: 'exponential', label: 'Exponential'},
    {value: 'matern', label: 'Matern'},
    {value: 'integral', label: 'Integral'},
    {value: 'stable', label: 'Stable'},
    {value: 'rational', label: 'Rational'},
    {value: 'cubic', label: 'Cubic'},
    {value: 'circular', label: 'Circular'},
    {value: 'spherical', label: 'Spherical'},
];

export const Variogram = ({datasetParams}: VariogramProps) => {

    const dispatch = useAppDispatch();

    const empiricParams = useAppSelector(getEmpiricParams);
    const modelParams = useAppSelector(getModelParams);
    const stage = useAppSelector(getStage);

    const [triggerEmpiric, empiricQuery] = uncorrApi.useLazyGetEmpiricQuery();
    const [triggerModel, modelQuery] = uncorrApi.useLazyGetModelQuery();

    React.useEffect(() => {
        dispatch(setEmpiricParams({}));
        triggerEmpiric({
            ...datasetParams,
            ...{},
        })
    }, []);

    const onModelSubmit = React.useCallback(
        (props: ModelFormCallback) => {
            const params = {
                ...props,
                modelName: props.modelName!.value,
            }
            dispatch(setModelParams(params));
            triggerModel({
                ...datasetParams,
                ...empiricParams,
                ...params,
            }).then(data => {
                dispatch(setModelParams({
                    modelName: params.modelName,
                    'var': data.data?.var,
                    range: data.data?.range,
                    nugget: data.data?.nugget,
                }))
            })
        },
        [datasetParams, empiricParams, modelParams]
    );

    const [ref, width] = useWidthTracker();

    return (
        <>
            {stage >= 3 && (
                <>
                    <Skeleton title='Configure kriging model'
                              isFetching={empiricQuery.isFetching || modelQuery.isFetching}>
                        <Form onSubmit={onModelSubmit} values={{
                            modelName: modelOptions.find(option => option.value === modelParams?.modelName),
                            'var': modelParams?.var,
                            range: modelParams?.range,
                            nugget: modelParams?.nugget,
                        }}>
                            <FormGroup>
                                <FormLabel text='Model'/>
                                <FormSelect
                                    name='modelName'
                                    placeholder='Choose model'
                                    options={modelOptions}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel text='Variance'/>
                                <FormNumberInput name='var' placeholder='Empty for autofit'/>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel text='Range'/>
                                <FormNumberInput name='range' placeholder='Empty for autofit'/>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel text='Nugget'/>
                                <FormNumberInput name='nugget' placeholder='Empty for autofit'/>
                            </FormGroup>
                        </Form>
                    </Skeleton>
                    <Skeleton
                        title='Variogram plot'
                        isError={empiricQuery.isError || modelQuery.isError}
                        errorMessage='Failed show plot'
                        isFetching={empiricQuery.isFetching || modelQuery.isFetching}
                        ref={ref}
                    >
                        <VariogramPlot
                            empiric={empiricQuery.data?.field || [[], [], []]}
                            model={modelQuery.data?.field.field}
                            columns={['Square distance', 'Dispersion']}
                            width={width * 0.8}
                            height={width * 0.6}
                        />
                    </Skeleton>
                </>
            )}
        </>
    );
};
