import React from "react";
import classNames from "classnames";
import './Scroll.css';

export const Scroll = ({children}: React.PropsWithChildren) => {

    const topAnchor = React.useRef<HTMLElement>(null);
    const bottomAnchor = React.useRef<HTMLElement>(null);

    const scrollTop = React.useCallback(() => {
        topAnchor.current?.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
    }, []);

    const scrollBottom = React.useCallback(() => {
        bottomAnchor.current?.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
    }, []);

    return (
        <div className='scrollable_container'>
            <div className='scroll_container'>
                <div className='scroll'>
                <span className='scroll_control' onClick={scrollTop}>
                    <div className={classNames('arrow', 'arrow_top')}/>
                </span>
                    <span className='scroll_control' onClick={scrollBottom}>
                    <div className={classNames('arrow', 'arrow_bottom')}/>
                </span>
                </div>
            </div>

            <span ref={topAnchor}/>
            {children}
            <span ref={bottomAnchor}/>
        </div>
    );
}