import React from "react";
import "./Footer.css"
import classNames from "classnames";


export interface FooterProps {
    className?: string;
}

export const Footer: React.FC<FooterProps> = ({className}) => (
    <footer className={classNames(className, 'footer')}>
        <section className='footer_credits'>
            <div>
                <p>
                    Developed by <a className='credits_link' target="_blank" href='https://sudo.team'>SUDO</a>
                </p>
            </div>
            <div className='credits_attributes'>
                <p>
                    Icons by <a className='credits_link'  target="_blank" href="https://icons8.com">
                        Icons8
                    </a>
                </p>
            </div>
        </section>
    </footer>
);
