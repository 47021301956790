import React from 'react';
import {getMin, mean, normalize} from "../../shared/utils/normalize";
import {Points} from "../../shared/types/field";
import {round} from "../../shared/utils/round";
import {toLatLon} from 'utm';
import {BasePlot} from "../../shared/types/BasePlot";

export type ScatterMapPlotProps = {
    columns: string[];
    points: Points;
    width: number;
    height: number;
    convertUtm?: boolean;
    utmZoneNumber?: number;
    utmZoneLetter?: string;
};

export const ScatterMapPlot = (props: ScatterMapPlotProps) => {
    const {columns, points, width, height, convertUtm, utmZoneNumber, utmZoneLetter} = props;

    const [markersSize, opacity] = React.useMemo(() => {
        const normalized = normalize(points[2]);
        const min = getMin(normalized);
        return [normalized.map(s => min + 1 + s * 20), normalized.map(s => s * 0.5 + 0.5)];
    }, [points]);

    const lat = points[0].slice(), lon = points[1].slice();
    if (convertUtm && utmZoneNumber && utmZoneLetter) {
        for (let i = 0; i < lat.length; i++) {
            const {latitude, longitude} = toLatLon(lat[i], lon[i], utmZoneNumber, utmZoneLetter);
            lat[i] = latitude;
            lon[i] = longitude;
        }
    }

    const pointsLabels = points[2].map(p => String(round(p, 5)));

    return (
        <BasePlot
            data={[{
                lat,
                lon,
                type: 'scattermapbox',
                mode: 'markers',
                marker: {
                    size: markersSize,
                    opacity: opacity,
                },
                text: pointsLabels,
            }]}
            defaultLayout={{
                title: 'Map plot',
                xaxis: {title: columns[0], exponentformat: 'none'},
                yaxis: {title: columns[1], exponentformat: 'none'},
            }}
            layout={{
                width,
                height,
			    mapbox: {
                    style: "open-street-map",
                    center: {
                        lat: mean(lat) || 0,
                        lon: mean(lon) || 0,
                    },
                    zoom: 3,
                },
            }}
        />
    );
};
