import React from "react";
import {getSimpleKrigingParams, setSimpleKrigingParams} from "../../../shared/store/methodsParamsSlices";
import {InterpolatorProps} from "../../../shared/types/interpolator";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/store";
import {getStage} from "../../../shared/store/interpolationStageSlice";
import {Block} from "../../../shared/ui/Block/Block";
import {Interpolation} from "../Interpolation";
import {uncorrApi} from "../../../shared/service/uncorrApi";
import {getEmpiricParams, getModelParams} from "../../../shared/store/variogramSlices";
import {Variogram} from "./Variogram";
import {CallbackProps, InterpolationForm} from "../../InterpolationForm";

export const SimpleKriging = ({datasetParams}: InterpolatorProps) => {

    const dispatch = useAppDispatch();
    const simpleKrigingParams = useAppSelector(getSimpleKrigingParams);
    const empiricParams = useAppSelector(getEmpiricParams);
    const modelParams = useAppSelector(getModelParams);
    const stage = useAppSelector(getStage);

    const onSubmit = React.useCallback((props: CallbackProps) => {
        dispatch(setSimpleKrigingParams(props));
    }, []);

    return (
        <>
            <Variogram datasetParams={datasetParams}/>
            {stage >= 4 && (
                <Block title='Customize interpolation'>
                    <InterpolationForm onSubmit={onSubmit} defaultValues={simpleKrigingParams ?? undefined}/>
                </Block>
            )}
            {stage >= 5 && empiricParams && modelParams && simpleKrigingParams && (
                <Interpolation useGetQuery={() => uncorrApi.useGetSimpleKrigingQuery({...datasetParams, ...empiricParams, ...modelParams, ...simpleKrigingParams})}/>
            )}
        </>
    );
};
