import React from 'react';
import "./Block.css"
import classNames from "classnames";


export interface BlockProps extends React.PropsWithChildren {
    title: string;
    disabled?: boolean;
}

export const Block = React.forwardRef<HTMLDivElement, BlockProps>((
    {title, disabled, children},
    ref
) => (
    <article className='block'>
        <header className="block_header">{title}</header>
        <div ref={ref} className={classNames("block_content", disabled && '__disabled')}>
            {children}
        </div>
    </article>
));
