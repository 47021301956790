import React from "react";
import classNames from "classnames";
import {Button as MaterialButton} from "@mui/material";


export interface ButtonProps extends React.PropsWithChildren {
    type?: 'submit' | 'reset' | 'button';
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((
    {type, onClick, className, children, style},
    ref
) => (
    <MaterialButton
        ref={ref}
        type={type}
        onClick={onClick}
        variant='contained'
        className={classNames('button', className)}
        sx={{backgroundColor: '#1DA1F2', ...style}}
    >
        {children}
    </MaterialButton>
));
