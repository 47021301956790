import {Controller, FieldValues} from "react-hook-form";
import {FieldPath} from "react-hook-form/dist/types";
import {Checkbox} from "@mui/material";


export interface FormCheckboxProps<V extends FieldValues> {
    name: FieldPath<V>
    disabled?: boolean
    required?: boolean
}

export function FormCheckbox<V extends FieldValues>(props: FormCheckboxProps<V>) {
    const {name, disabled, required} = props;

    return (
        <Controller
            name={name}
            rules={{required}}
            render={({field}) => (
                <Checkbox {...field} checked={field.value} disabled={disabled}/>
            )}
        />
    );
}