export function getMax(arr: number[]) {
    let len = arr.length;
    let max = -Infinity;

    while (len--) {
        max = arr[len] > max ? arr[len] : max;
    }
    return max;
}

export function getMin(arr: number[]) {
    let len = arr.length;
    let min = Infinity;

    while (len--) {
        min = arr[len] < min ? arr[len] : min;
    }
    return min;
}

export function normalize(arr: number[]) {
    const min = getMin(arr);
    const max = getMax(arr);
    if (min === max) {
        return arr;
    }
    return arr.map(el => (el - min) / (max - min));
}

export function mean(arr: number[]) {
    if (arr.length === 0) {
        return null;
    }
    return arr.reduce((sum, v) => sum + v) / arr.length;
}
