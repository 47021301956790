import React from 'react';
import {DatasetParams} from "../../shared/types/dataset";
import {Block} from "../../shared/ui/Block/Block";
import {useAppDispatch} from "../../shared/hooks/store";
import {setDatasetParams} from "../../shared/store/datasetParamsSlice";
import {Form, FormGroup, FormLabel, FormSelect} from "../../shared/ui/Form";
import {Option} from "../../shared/ui/Form/types";

export interface DatasetConfigurationProps {
    datasetParams: DatasetParams;
}

type DatasetFilters = {
    x: Option;
    y: Option;
    z: Option;
    normalized: Option;
};

export const DatasetConfiguration = ({datasetParams}: DatasetConfigurationProps) => {

    const dispatch = useAppDispatch();

    const columnsOptions = React.useMemo(
        () => datasetParams.columns.map(col => ({value: col, label: col})),
        [datasetParams.columns]
    );

    const normalizedOptions = React.useMemo(
        () => [
            {value: 'false', label: 'False'},
            {value: 'true', label: 'True'},
        ],
        []
    );

    const defaultValues = {
        'x': {value: datasetParams.columns[0], label: datasetParams.columns[0]},
        'y': {value: datasetParams.columns[1], label: datasetParams.columns[1]},
        'z': {value: datasetParams.columns[2], label: datasetParams.columns[2]},
        'normalized': normalizedOptions[Number(datasetParams.normalized)],
    };

    const onSubmit = React.useCallback((data: DatasetFilters) => {
        dispatch(setDatasetParams({
            ...datasetParams,
            columns: [data.x.value, data.y.value, data.z.value],
            normalized: data.normalized.value === 'true',
        }));
    }, []);

    return (
        <Block title='Configure dataset'>
            <Form onSubmit={onSubmit} defaultValues={defaultValues}>
                <FormGroup>
                    <FormLabel text="X-axis"/>
                    <FormSelect name='x' options={columnsOptions}/>
                </FormGroup>
                <FormGroup>
                    <FormLabel text="Y-axis"/>
                    <FormSelect name='y' options={columnsOptions}/>
                </FormGroup>
                <FormGroup>
                    <FormLabel text="Z-axis"/>
                    <FormSelect name='z' options={columnsOptions}/>
                </FormGroup>
                <FormGroup>
                    <FormLabel text="Normalized"/>
                    <FormSelect name='normalized' options={normalizedOptions}/>
                </FormGroup>
            </Form>
        </Block>
    );
};
