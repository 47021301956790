import * as React from 'react';
import {Button} from "../../shared/ui/Button/Button";
import useClipboard from "react-use-clipboard";
import {useLinkOnCurrentState} from "../../shared/hooks/useLinkOnCurrentState";

export const CopyLinkOnCurrentPageButton = () => {
    const link = useLinkOnCurrentState();
    const [isCopied, setCopied] = useClipboard(link);

    return (
        <Button onClick={setCopied}>
            {isCopied ? "Link copied!" : "Copy link on result"}
        </Button>
    );
};