import React from "react";
import {uncorrApi} from "../../shared/service/uncorrApi";
import {Table} from "../../shared/ui/Table/Table";
import {DatasetParams} from "../../shared/types/dataset";
import {Skeleton} from "../../shared/ui/Skeleton/Skeleton";
import useWidthTracker from "../../shared/hooks/useWidthTracker";
import {HistogramPlot} from "./HistogramPlot";
import {DatasetPlot} from "../../features/dataset/DatasetPlot";


export interface FilePreviewProps {
    datasetParams: DatasetParams;
}

export const DatasetStat = ({datasetParams}: FilePreviewProps) => {

    const {
        isError,
        isFetching,
        data,
    } = uncorrApi.useGetDatasetStatQuery(datasetParams);

    const [ref, width] = useWidthTracker();

    return (
        <Skeleton
            title='File stats'
            isError={isError}
            errorMessage='Error processing dataset'
            isFetching={isFetching}
            ref={ref}
        >
            {data && (
                <>
                    <Table
                        headers={datasetParams.columns}
                        columns={Array.from({length: 10}, (_, k) => String(k + 1))}
                        points={data.preview.field.map(col => col.slice(0, 10))}
                    />
                    <Table
                        headers={['Number of points']}
                        points={[[data.nPoints]]}
                    />
                    <Table
                        headers={['Min', 'Max', 'Average', 'Median']}
                        columns={datasetParams.columns}
                        points={[
                            [data.xMin, data.yMin, data.zMin],
                            [data.xMax, data.yMax, data.zMax],
                            ['-', '-', data.zAvg],
                            ['-', '-', data.zMed],
                        ]}
                    />
                    <DatasetPlot
                        datasetParams={datasetParams}
                        points={data.preview.field}
                        width={width}
                    />
                    <HistogramPlot
                        counts={data.histogram.counts}
                        binEdges={data.histogram.binEdges}
                        nPoints={data.nPoints}
                        width={width * 0.8}
                        height={width * 0.6}
                    />
                </>
            )}
        </Skeleton>
    );
};
