import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DatasetParams} from "../types/dataset";
import {RootState} from "./store";

export const datasetParamsSlice = createSlice({
    name: 'datasetParams',
    initialState: null as DatasetParams | null,
    reducers: {
        clearDatasetParams: () => null,
        setDatasetParams: (state, action: PayloadAction<DatasetParams>) => action.payload,
    },
});

export const { setDatasetParams, clearDatasetParams } = datasetParamsSlice.actions;
export const getDatasetParams = (state: RootState) => state.datasetParams;
