import React from "react";
import {Form, FormGroup, FormLabel, FormSelect} from "../../shared/ui/Form";
import {FormControlLabel} from "@mui/material";
import {FormCheckbox} from "../../shared/ui/Form/FormCheckbox";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/store";
import {
    getInterpolationPlotSettings,
    setInterpolationPlotSettings,
    InterpolationPlotSettings,
} from "../../shared/store/InterpolationPlotSettingsSlice";
import {useFormContext} from "react-hook-form";
import {FormNumberInput} from "../../shared/ui/Form/FormNumberInput";
import {FormColorPicker} from "../../shared/ui/Form/FormColorPicker";
import {FormColorRangeList} from "../../shared/ui/Form/FormColorRangeList";
import {Option} from "../../shared/ui/Form/types";


interface InterpolationPlotFormCallback extends Omit<InterpolationPlotSettings, 'colorscaleType'> {
    colorscaleType: Option
}


function DefaultContoursInputs() {
    const methods = useFormContext();
    const isContoursEnabled = methods.watch('isDefaultContoursEnabled');

    return (
        <>
            <FormGroup>
                <FormControlLabel
                    label='Contours enabled'
                    control={<FormCheckbox name='isDefaultContoursEnabled'/>}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel text='Amount of contours'/>
                <FormNumberInput name='ncontours' disabled={!isContoursEnabled}/>
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    label='Show contour label'
                    control={<FormCheckbox name='showlabels' disabled={!isContoursEnabled}/>}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel text='Contours color'/>
                <FormColorPicker name='color' disabled={!isContoursEnabled}/>
            </FormGroup>
        </>
    );
}

function ColorscaleInputs() {
    const methods = useFormContext();
    const isDefaultColorscale = methods.watch('isDefaultColorscale');

    return (
        <>
            <FormGroup>
                <FormControlLabel
                    label='Use default colorscale'
                    control={<FormCheckbox name='isDefaultColorscale'/>}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel text='Colorscale'/>
                <div style={{width: 200}}>
                    <FormSelect
                        name='colorscaleType'
                        placeholder='Jet'
                        disabled={!isDefaultColorscale}

                        options={[
                            {value: 'Jet', label: 'Jet'},
                            {value: 'Blackbody', label: 'Blackbody'},
                            {value: 'Bluered', label: 'Bluered'},
                            {value: 'Blues', label: 'Blues'},
                            {value: 'Cividis', label: 'Cividis'},
                            {value: 'Earth', label: 'Earth'},
                            {value: 'Electric', label: 'Electric'},
                            {value: 'Greens', label: 'Greens'},
                            {value: 'Greys', label: 'Greys'},
                            {value: 'Hot', label: 'Hot'},
                            {value: 'Picnic', label: 'Picnic'},
                            {value: 'Portland', label: 'Portland'},
                            {value: 'Rainbow', label: 'Rainbow'},
                            {value: 'RdBu', label: 'RdBu'},
                            {value: 'Reds', label: 'Reds'},
                            {value: 'Viridis', label: 'Viridis'},
                            {value: 'YlGnBu', label: 'YlGnBu'},
                            {value: 'YlOrRd', label: 'YlOrRd'},
                        ]}
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <FormLabel text='Custom range'/>
                <FormColorRangeList name='colorscaleRanges' disabled={isDefaultColorscale}/>
            </FormGroup>
        </>
    );
}


export function InterpolationPlotForm() {

    const dispatch = useAppDispatch();
    const settings = useAppSelector(getInterpolationPlotSettings);

    const onSubmit = (data: InterpolationPlotFormCallback) => {
        dispatch(setInterpolationPlotSettings({
            ...data,
            colorscaleType: data.colorscaleType.value,
        }));
    };

    return (
        <Form
            onSubmit={onSubmit}
            defaultValues={{
                ...settings,
                colorscaleType: {value: settings.colorscaleType, label: settings.colorscaleType},
            }}
        >
            <DefaultContoursInputs/>

            <FormGroup>
                <FormLabel text='Additional contours'/>
                <FormColorRangeList name='additionalContours'/>
            </FormGroup>

            <ColorscaleInputs/>
        </Form>
    );
}
