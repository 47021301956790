import React from 'react';
import {getMin, normalize} from "../../shared/utils/normalize";
import {Points} from "../../shared/types/field";
import {round} from "../../shared/utils/round";
import {BasePlot} from "../../shared/types/BasePlot";

export interface ScatterPlotProps {
    columns: string[];
    points: Points;
    width: number;
    height: number;
}

export const ScatterPlot = ({columns, points, width, height}: ScatterPlotProps) => {

    const [markersSize, opacity] = React.useMemo(() => {
        const normalized = normalize(points[2]);
        const min = getMin(normalized);
        return [normalized.map(s => min + 1 + s * 20), normalized.map(s => s * 0.5 + 0.5)];
    }, [points]);

    const pointsLabels = points[2].map(p => String(round(p, 5)));

    return (
        <BasePlot
            data={[{
                x: points[0],
                y: points[1],
                type: 'scatter',
                mode: 'markers',
                marker: {
                    size: markersSize,
                    opacity: opacity,
                },
                text: pointsLabels,
            }]}
            defaultLayout={{
                title: 'Dataset plot',
                xaxis: {title: columns[0], exponentformat: 'none'},
                yaxis: {title: columns[1], exponentformat: 'none'},
            }}
            layout={{
                width,
                height,
            }}
        />
    );
};
