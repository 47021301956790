import {Points} from "../types/field";

export function toCsv(columns: string[], points: Points) {
    const n = points[0].length
    const rows = new Array<string>(n + 1);
    rows[0] = columns.join(',');
    for (let i = 0; i < n; i++) {
        rows[i + 1] = `${points[0][i]},${points[1][i]},${points[2][i]}`;
    }
    return rows.join('\r\n');
}
