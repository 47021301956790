import {Methods} from "../types/methods";
import {RootState} from "./store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {clearDatasetParams, setDatasetParams} from "./datasetParamsSlice";

export const methodSlice = createSlice({
    name: 'method',
    initialState: null as Methods | null,
    reducers: {
        setMethod: (state, action: PayloadAction<Methods>) => action.payload,
    },
    extraReducers: builder => {
        builder.addCase(clearDatasetParams, () => null);
        builder.addCase(setDatasetParams, () => null);
    },
});

export const { setMethod } = methodSlice.actions;
export const getMethod = (state: RootState) => state.method;
