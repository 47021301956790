import React from "react";
import {useForm, FormProvider, FieldValues, SubmitHandler, DeepPartial} from "react-hook-form";
import {Button} from "../Button/Button";

export interface FormProps<V extends FieldValues> extends React.PropsWithChildren {
    onSubmit: SubmitHandler<V>
    defaultValues?: DeepPartial<V>
    values?: V
}

export function Form<V extends FieldValues>(props: FormProps<V>) {
    const {onSubmit, children, defaultValues, values} = props;
    const methods = useForm<V>({defaultValues, values});

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
                {children}
            </FormProvider>
            <div style={{textAlign: 'center', marginTop: 8}}>
                <Button type="submit">
                    Submit
                </Button>
            </div>
        </form>
    );
}