import React from 'react';
import NumericInput from 'react-numeric-input';
import './NumberInput.css';

export const NumberInput = React.forwardRef<HTMLInputElement, Omit<NumericInput.NumericInputProps, 'style' | 'className'>>(
    (props, _) => (
        <NumericInput
            {...props}
            style={false}
            className='number-input'
        />
    )
);
