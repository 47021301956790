import {Controller, FieldValues, Path, PathValue, useFormContext} from "react-hook-form";
import React from "react";
import Select from "react-select";
import {FieldPath} from "react-hook-form/dist/types";

export interface FormSelectProps<V extends FieldValues> {
    name: FieldPath<V>;
    options: PathValue<V, Path<V>>[];
    placeholder?: string;
    disabled?: boolean;
    controlStyle?: React.CSSProperties;
}

export function FormSelect<V extends FieldValues>(props: FormSelectProps<V>) {
    const {name, options, placeholder, disabled, controlStyle} = props;
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            rules={{required: true}}
            control={control}
            render={({field}) => (
                <Select
                    {...field}
                    options={options}
                    isDisabled={disabled}
                    placeholder={placeholder}
                    menuPlacement='auto'
                    styles={{
                        control: (baseStyles, _) => ({
                            ...baseStyles,
                            ...controlStyle,
                        }),
                    }}
                />
            )}
        />
    );
}
