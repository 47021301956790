import React from "react";
import {getDatasetParams} from "../../shared/store/datasetParamsSlice";
import {DatasetStat} from "../../widgets/dataset/DatasetStat";
import {FileUpload} from "../../features/dataset/FileUpload";
import {useAppSelector} from "../../shared/hooks/store";
import {ChooseMethod} from "../../features/ChooseMethod";
import {DatasetConfiguration} from "../../features/dataset/DatasetConfiguration";
import {getMethod} from "../../shared/store/methodSlice";
import {Methods} from "../../shared/types/methods";
import {InterpolatorProps} from "../../shared/types/interpolator";
import {UniversalKriging} from "../../features/interpolation/kriging/UniversalKriging";
import {OrdinaryKriging} from "../../features/interpolation/kriging/OrdinaryKriging";
import {Linear} from "../../features/interpolation/Linear";
import {Rbf} from "../../features/interpolation/Rbf";
import {NearestNeighbour} from "../../features/interpolation/NearestNeighbour";
import {Idw} from "../../features/interpolation/Idw";
import {getStage} from "../../shared/store/interpolationStageSlice";
import {CardGrid} from "../../shared/ui/CardGrid/CardGrid";
import {SimpleKriging} from "../../features/interpolation/kriging/SimpleKriging";

export const METHOD_TO_COMPONENT: Record<Methods, React.FC<InterpolatorProps>> = {
    'idw': Idw,
    'nearest': NearestNeighbour,
    'rbf': Rbf,
    'linear': Linear,
    'simpleKriging': SimpleKriging,
    'ordinaryKriging': OrdinaryKriging,
    'universalKriging': UniversalKriging,
}


export const InterpolationPage = () => {

    const datasetParams = useAppSelector(getDatasetParams);
    const method = useAppSelector(getMethod);
    const stage = useAppSelector(getStage);

    const Method = React.useMemo(() => {
        if (method && method in METHOD_TO_COMPONENT) {
            return METHOD_TO_COMPONENT[method];
        }
    }, [method]);

    return (
        <CardGrid>
            <FileUpload/>
                {stage >= 1 && datasetParams && (
                    <>
                        <DatasetConfiguration datasetParams={datasetParams}/>
                        <DatasetStat datasetParams={datasetParams}/>
                        <ChooseMethod/>
                        {stage >= 2 && Method && <Method datasetParams={datasetParams}/>}
                    </>
                )}
        </CardGrid>
    );
};
