import React from "react";
import {Points} from "../shared/types/field";
import * as Plotly from "plotly.js";
import {BasePlot} from "../shared/types/BasePlot";

export interface VariogramPlotProps {
    empiric?: Points;
    model?: Points;
    columns: string[];
    width: number;
    height: number;
}

export const VariogramPlot: React.FC<VariogramPlotProps> = (
    {empiric, model, columns, width, height}
) => {
    const data = React.useMemo(() => {
        const data: Plotly.Data[] = [];

        if (model) {
            data.push({
                x: model[0],
                y: model[1],
                type: 'scatter',
            });
        }

        if (empiric) {
            data.push({
                x: empiric[0],
                y: empiric[1],
                type: 'scatter',
                mode: 'markers',
            });
        }

        return data;
    }, [empiric, model]);

    return <BasePlot
        data={data}
        defaultLayout={{
            title: 'Variogram plot',
            xaxis: {title: columns[0], exponentformat: 'none'},
            yaxis: {title: columns[1], exponentformat: 'none'},
        }}
        layout={{
            width,
            height,
        }}
    />;
};
